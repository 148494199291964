import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import Box from '@mui/material/Box'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { NoResults } from '@Components/search/NoResults'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { RootState } from '../../../store/Store'
import { formatToDefaultCurrency } from 'src/utils/number'
import { formatDate } from 'src/utils/date'

interface DetailsTransactionsInterface {
  dataItens: any
  statusLoad?: boolean
  statusError?: boolean
}

const format = (item, type, style = 'yyyy/MM/dd HH:mm:ss') => {
  const patternData = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/

  if (item === '' || item === null || item === undefined) return
  switch (type) {
    case 'number':
      if (isNaN(item)) return item
      return formatToDefaultCurrency(item)
    case 'date':
      if (patternData.test(item)) return item
      return formatDate(new Date(item), style)
  }
}

const TransactionDetails: React.FC<DetailsTransactionsInterface> = ({
  statusLoad,
  statusError,
  dataItens,
}: DetailsTransactionsInterface) => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  const dataInstallments = dataItens && dataItens.merchant_transactions ? dataItens.merchant_transactions : ''

  return (
    <Box display='flex' flexDirection='column'>
      {dataItens && !statusError ? (
        <Fragment>
          <div>
            <div>
              <span>{format(dataItens.date, 'date')}</span>
              <h4>{dataItens.name}</h4>
              <p>
                {i18n._(
                  t`autorização: ${dataItens.authorizationId ? dataItens.authorizationId : i18n._(t`- Não informado`)}`,
                )}
              </p>
            </div>
            <div>
              <CreditCardIcon style={{ fontSize: '30px', marginRight: '29px' }} />
              {`***${dataItens.numCardLast}`}
            </div>
          </div>
          <div>
            <h2>{dataItens.operation}</h2>
            <span>
              {dataItens.installments > 0 && <small>{`${dataItens.installments}x`}</small>}
              {formatToDefaultCurrency(Number(dataItens.value))}
            </span>
            <Box>
              <TableContainer style={{ maxHeight: '120px', marginTop: '40px' }}>
                <Table>
                  <TableBody>
                    {dataInstallments && dataInstallments.length > 0
                      ? dataInstallments.map((res, i) => (
                          <TableRow key={i}>
                            <TableCell>
                              <Typography variant='body1'>{i18n._(t`Vence em`)}</Typography>
                              {format(res.event_date, 'date')}
                            </TableCell>
                            <TableCell>
                              <Typography variant='body1'>{i18n._(t`Desc.`)}</Typography>
                              {formatToDefaultCurrency(Number(res.discount))}
                            </TableCell>
                            <TableCell>
                              <Typography variant='body1'>{i18n._(t`A receber`)}</Typography>
                              {formatToDefaultCurrency(Number(res.net_value))}
                            </TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <p>{`${dataItens.acquirer} - ${
              dataItens.acquirerId ? `ID: ${dataItens.acquirerId}` : i18n._(t`Não informado`)
            }`}</p>
          </div>
        </Fragment>
      ) : (
        <Box>
          <NoResults message={i18n._(t`Essa transação nao possui detalhes`)} />
        </Box>
      )}
    </Box>
  )
}

export { TransactionDetails }
