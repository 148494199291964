import { Dispatch } from 'redux'
import { mountAuthHeaders, interceptor } from '../utils'
import { IError } from '@pismo/sdk-core'
import { IGetDetailsTransactionResult, IMarketplaceDetailsGet, Marketplace } from 'src/resources/marketplace'
import { IGet, ITransactionDetailsResult, Merchants } from 'src/resources/merchants'

export enum TransactionDetailsEvents {
  TRANSACTION_DETAILS_MKT_RESULT = 'TRANSACTION_DETAILS_MKT_RESULT',
  TRANSACTION_DETAILS_MERCHANT_RESULT = 'TRANSACTION_DETAILS_MERCHANT_RESULT',
  LOADING_DETAILS = 'LOADING_DETAILS',
  ERROR_TRANSACTION_DETAILS = 'ERROR_TRANSACTION_DETAILS',
  CLEAR_ITEMS_DETAILS = 'CLEAR_ITEMS_DETAILS',
}

export interface TransactionDetailsAction {
  type: TransactionDetailsEvents
  payload?: any
}

export interface TransactionDetails {
  loadingDetails: boolean
  transactionDetailsResult: IGetDetailsTransactionResult | ITransactionDetailsResult | null
  errorDetails: string | null
}

export const transactionDetails: TransactionDetails = {
  loadingDetails: true,
  transactionDetailsResult: null,
  errorDetails: null,
}

export const transactionDetailsReducer = (
  state: TransactionDetails = transactionDetails,
  action: TransactionDetailsAction,
) => {
  switch (action.type) {
    case TransactionDetailsEvents.TRANSACTION_DETAILS_MKT_RESULT:
      const item = action.payload.item
      return {
        ...state,
        transactionDetailsResult: {
          date: item.updated || 0,
          name: item.gateway || '',
          authorizationId: item.gateway_transaction || 0,
          acquirerId: item.gateway_processor_transaction_id,
          numCardLast: item.card.number || 1111,
          operation: item.operation || '',
          installments: item.installments || 0,
          value: item.value || 0,
          acquirer: item.acquirer || '',
          merchant_transactions: item.merchant_transactions,
        },
        loadingDetails: false,
      }
    case TransactionDetailsEvents.TRANSACTION_DETAILS_MERCHANT_RESULT:
      const itemMerchant = action.payload.item
      return {
        ...state,
        transactionDetailsResult: {
          date: itemMerchant.date || 0,
          name: itemMerchant.name || '',
          authorizationId: itemMerchant.authorization_code || 0,
          acquirerId: itemMerchant.authorization_code,
          numCardLast: itemMerchant.card.last_four_digits || 1111,
          operation: action.payload.operation || '',
          installments: itemMerchant.installments || 0,
          value: itemMerchant.total || 0,
          acquirer: itemMerchant.merchant.name || '',
        },
        loadingDetails: false,
      }
    case TransactionDetailsEvents.LOADING_DETAILS:
      return { ...state, loadingDetails: true }
    case TransactionDetailsEvents.CLEAR_ITEMS_DETAILS:
      return {
        ...state,
        loadingDetails: false,
        transactionDetailsResult: null,
        errorDetails: null,
      }
    case TransactionDetailsEvents.ERROR_TRANSACTION_DETAILS:
      return {
        ...state,
        errorDetails: true,
        loadingDetails: false,
      }
    default:
      return state
  }
}

export interface ITransactionDetailsActions {
  // setTerm: (term: string) => (dispatch: Dispatch) => void
  sellerTransactionMktDetails: (credentials: any, props?: IMarketplaceDetailsGet) => (dispatch: Dispatch) => void
  sellerTransactionMerchantDetails: (
    credentials: any,
    merchantID: number,
    idTransaction: number,
    props?: IGet,
  ) => (dispatch: Dispatch) => void
  // sellerPagination: (page: number, credentials: any) => (dispatch: Dispatch) => void
  clearDetails: () => TransactionDetailsAction
  loadDetails: () => TransactionDetailsAction
}

export const TransactionDetailsActions: ITransactionDetailsActions = {
  sellerTransactionMktDetails: (credentials: any, props?: IMarketplaceDetailsGet) => async (dispatch: Dispatch) => {
    dispatch({ type: TransactionDetailsEvents.LOADING_DETAILS, payload: true })

    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials.user),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    let response = await MarketplaceClient.getMarketplaceDetails(props)

    if ((response as IError).error) {
      dispatch({
        type: TransactionDetailsEvents.ERROR_TRANSACTION_DETAILS,
        payload: (response as IError).error.message,
      })
      return
    }

    const data = {
      item: response,
      authId: credentials.authorization_id,
    }

    dispatch({ type: TransactionDetailsEvents.TRANSACTION_DETAILS_MKT_RESULT, payload: data })
  },

  sellerTransactionMerchantDetails:
    (credentials: any, merchantID: number, idTransaction: number) => async (dispatch: Dispatch) => {
      dispatch({ type: TransactionDetailsEvents.LOADING_DETAILS, payload: true })

      const MerchantsClient = Merchants({
        headers: mountAuthHeaders(credentials.user),
      })

      const client = MerchantsClient.getClient()

      interceptor(client, dispatch)
      try {
        const response = await MerchantsClient.getMarketplaceDetails(merchantID, idTransaction)
        const data = {
          item: response.data,
          operation: credentials.operation,
        }

        dispatch({ type: TransactionDetailsEvents.TRANSACTION_DETAILS_MERCHANT_RESULT, payload: data })
      } catch (err: any) {
        dispatch({ type: TransactionDetailsEvents.ERROR_TRANSACTION_DETAILS, payload: err?.error?.message })
      }
    },

  clearDetails: () => ({ type: TransactionDetailsEvents.CLEAR_ITEMS_DETAILS }),
  loadDetails: () => ({ type: TransactionDetailsEvents.LOADING_DETAILS }),
}
