import React, { useEffect, Fragment } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'

import { Top } from '@Components/sellers'
import { SellerActions } from '../../../store'

import { SellerEditPage } from './SellerEditPage'
import { SellerEvent } from './SellerEvent'
import { SellerTax } from './SellerTax'
import { SellerTransactions } from './SellerTransactions'
import { SellerWorkflow } from './SellerWorkflow'
import SellerAnticipation from './SellerAnticipation'

import Home from '@mui/icons-material/Home'
import AppBar from '@Components/appBar'
import PageContent from '@Components/pageContent'
import Page from '@Components/page'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import EventIcon from '@mui/icons-material/Event'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { RootState } from '../../../store/Store'
import Loading from '@Components/loading'

const { getSeller } = SellerActions

const SellerContainer: React.FC = () => {
  const { id, page } = useParams<any>()

  const { i18n } = useSelector((state: RootState) => state.translation)
  const { seller, loading } = useSelector((state: RootState) => state.seller)
  const user = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch()
  const history = useHistory()

  const menuElements = [
    {
      Icon: Home,
      label: i18n._(t`home`),

      name: 'home',
    },
    {
      Icon: AttachMoneyIcon,
      label: i18n._(t`taxas / tarifas`),
      name: 'tax',
    },
    {
      Icon: SwapHorizIcon,
      label: i18n._(t`transações`),
      name: 'transactions',
    },
    {
      Icon: EventIcon,
      label: i18n._(t`agenda a receber`),
      name: 'event',
    },
    {
      Icon: EventAvailableIcon,
      label: i18n._(t`antecipacao`),
      name: 'anticipation',
    },
    {
      Icon: PlaylistAddCheckIcon,
      label: i18n._(t`status do processo`),
      name: 'workflow',
    },
  ]

  useEffect(() => {
    if (!seller || seller?.id?.toString() !== id) {
      dispatch(getSeller(id, user))
    }
  }, [])

  const onBack = () => history.push('/sellers')
  const menuClicked = (page: string) => history.push(`/sellers/edit/${id}/${page}`)
  const getPage = () => {
    switch (page) {
      case 'home':
        return <SellerEditPage seller={seller} />
      case 'tax':
        return <SellerTax seller={seller} />
      case 'transactions':
        return <SellerTransactions merchantId={id} />
      case 'event':
        return <SellerEvent sellerId={id} />
      case 'anticipation':
        return <SellerAnticipation merchantId={id} />
      case 'workflow':
        return <SellerWorkflow seller={seller} />
      default:
        return <div>page not found</div>
    }
  }

  return (
    <Page>
      <AppBar title={`${i18n._(t`Estabelecimentos`)} - ${seller?.name || ''}`} back={onBack}></AppBar>
      <br />
      <Top items={menuElements} name={seller?.name || ''} page={page} onBack={onBack} onMenuClick={menuClicked} />
      {loading && <Loading />}
      <PageContent>{seller && <Fragment>{getPage()}</Fragment>}</PageContent>
    </Page>
  )
}

export { SellerContainer }
